<script setup lang="ts">
import { useEmployerStore } from "@/store/employer";
import StepOne from "./StepOne.vue";
import StepTwo from "./StepTwo.vue";
import StepThree from "./StepThree.vue";
import StepFour from "./StepFour.vue";

const employerStore = useEmployerStore();
const { employer } = storeToRefs(employerStore);
const show = ref(true);
const currentStepNumber = ref(0);

const steps = shallowRef([
  {
    step: 1,
    title: "Company Information",
    desc: "The first thing you should do is fill out your company information.",
    component: StepOne,
    img: "/employers/company-information.png",
  },
  {
    step: 2,

    title: "Billing Information",
    desc: "In order to purchase Job Credits to create Job Posts, you need to fill out your billing information.",
    component: StepTwo,
    img: "/employers/billing-information.png",
  },
  {
    step: 3,
    title: "Purchase Job Credits",
    desc: "You can then purchase Job Credits by navigating to the Payments page.",
    component: StepThree,
    img: "/employers/purchase-job-credits.png",
  },
  {
    step: 4,
    title: "Create Job Post",
    desc: "Finally, after purchasing Job Credits, you can create your first Job Post by navigating to the Jobs page.",
    component: StepFour,
    img: "/employers/create-job.png",
  },
]);

const currentStep = computed(() => steps.value.find((step) => step.step === currentStepNumber.value));
const isLastStep = computed(() => (currentStep.value ? currentStepNumber.value === steps.value.length : false));

const handleNextStep = async () => {
  if (!isLastStep.value) return (currentStepNumber.value += 1);
  try {
    if (!employer.value) throw Error(ERROR_MESSAGES.employer_not_found);
    employer.value.onboarding_seen = true;
    const { error } = await employerStore.updateEmployer({ onboarding_seen: true });
    if (error) throw error;
  } catch (e) {
    throw createError({ statusMessage: ERROR_MESSAGES.error_updating_profile });
  }
};

const prevStep = () => {
  currentStepNumber.value -= 1;
};
</script>

<template>
  <div v-if="employer">
    <DialogModal
      :show="!employer.onboarding_seen"
      @close="show = false"
      :closeable="false"
      max-width="w-full sm:max-w-3xl"
      footer-justify="justify-between"
    >
      <template #title v-if="!currentStep">
        <p>Welcome to {{ $config.public.appName }}!</p>
        <p class="font-normal text-sm">Let's get you started with the basics.</p>
      </template>
      <template v-else #title>
        <p>{{ currentStep!.title }}</p>
        <p class="font-normal text-sm">{{ currentStep!.desc }}</p>
      </template>
      <template #content>
        <transition name="slide-fade" mode="out-in">
          <div
            v-if="!currentStep"
            class="relative flex items-center justify-center bg-light-gray rounded-lg w-full h-full md:h-[388px] lg:h-[406px]"
          >
            <BaseImage src="/employers/get-started.png" alt="Get started" sizes="720px" class="w-full rounded-lg object-cover" />
            <PrimaryButton @click="handleNextStep" class="absolute"> Let's get started </PrimaryButton>
          </div>
          <div v-else>
            <transition name="slide-fade" mode="out-in">
              <div class="bg-light-gray z-10 rounded-lg w-full h-full md:h-[388px] lg:h-[406px]">
                <BaseImage :src="currentStep.img" :alt="currentStep.title" sizes="720px" class="w-full rounded-lg object-cover" />
              </div>
              <!-- <component :is="currentStep.component" :title="currentStep.title" :desc="currentStep.desc"></component> -->
            </transition>
            <div class="grid grid-cols-3 items-center mt-3 bg-white-gray p-2 rounded-lg">
              <OutlineButton v-if="currentStepNumber > 1" @click="prevStep" class="w-fit"> Prev </OutlineButton>
              <p class="font-semibold text-xs text-center" :class="{ 'col-start-2': currentStepNumber <= 1 }">
                {{ currentStepNumber }} / {{ steps.length }}
              </p>
              <PrimaryButton @click="handleNextStep" :class="{ 'col-start-3': currentStepNumber <= 1 }" class="w-fit justify-self-end">
                {{ isLastStep ? "Finish" : "Next" }}
              </PrimaryButton>
            </div>
          </div>
        </transition>
      </template>
    </DialogModal>
  </div>
</template>
